<template>
<div></div>
</template>

<script>
export default {
  mounted() {
    this.$http.get(process.env.VUE_APP_URL + 'database/visit/add').then();
    // if (this.isMobile()){
    //   console.log(1)
    // }else {
    //   this.$router.push('/pc/home')
    // }
    this.$router.push('/pc/home')
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  }
}
</script>

<style scoped>

</style>
